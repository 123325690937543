.lastcolumnSeparator .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.MuiDataGrid-columnHeaderReorder .MuiDataGrid-columnSeparator--sideRight {
  display: none !important;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.packshrink-logo {
  height: 180px;
  width: 125px;
  margin-left: 60px;
  color: white;
  margin-top: 10px;
}

.packshrink-logo:hover {
  opacity: 0.8;
  cursor: pointer;
}

.share-image:hover {
  opacity: 0.8;
  cursor: pointer;
}

.suggested-item-image:hover {
  opacity: 0.8;
  cursor: pointer;
}

.toggle-button-group-scroller::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.selector-toggle-button {
  color: white !important;
}

.detail-panel-content-hover:hover {
  opacity: 0.8;
  cursor: pointer;
}

.selectCell:hover {
  background-color: gray;
  opacity: 0.4;
}
